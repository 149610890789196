@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Pretendard", sans-serif;
  }
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-T.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-EL.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-L.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-R.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-M.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-SB.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-B.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-EB.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-BL.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.inner.page {
  margin-bottom: 100px;
}

.page-tit {
  font-weight: 700;
  font-size: 35px;
  color: #333;
  padding-top: 30px;
}

.page-detail-tit {
  font-weight: 700;
  font-size: 35px;
  color: #999;
  padding-top: 30px;
}

.page-sub-tit {
  font-weight: 700;
  font-size: 30px;
  color: #666;
}

span.page-tit + span.page-sub-tit {
  margin-left: 10px;
}

.text-input {
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  color: #333;
  padding: 8px 10px;
  font-size: 16px;
  width: 380px;
}

.text-input::placeholder {
  color: #aaa;
  font-size: 16px;
}

.text-input:focus {
  outline: none;
}

button {
  border-radius: 5px;
  color: #fff;
  background-color: #848484;
  padding: 6px 18px;
  min-width: 60px;
  max-height: 40px;
}

button.blue {
  background-color: #6ea7db;
}

button.dark {
  background-color: #333;
}

button.white {
  background-color: #fff;
  color: #6ea7db;
  border: 1px solid #6ea7db;
}

button + button {
  margin-left: 10px;
}

select {
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  padding: 8px 10px;
  font-size: 16px;
  text-align: center;
}

select:invalid {
  color: #aaa;
}

.header-shadow {
  box-shadow: 0px 4px 4px 3px rgba(134, 134, 134, 0.25);
}

summary {
  cursor: pointer;
}

.checkbox {
  display: none;
}

.checkbox + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  margin-right: 10px;
  position: relative;
}

.checkbox:checked + label::after {
  content: "✓";
  display: inline-block;
  color: #6ea7db;
  text-align: center;
  position: absolute;
  left: 1px;
  top: -4px;
}

.table {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  color: #666;
  margin-top: 25px;
}

.table .row {
  width: 1200px;
  padding: 5px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  align-items: center;
}

.table .row div {
  display: flex;
  align-items: center;
  line-break: anywhere;
}

.table .row div div.content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table .row.header {
  background: #6ea7db;
  color: #fff;
  border-bottom: none;
}

.table .row.detail {
  background: #f8fbff;
  border-bottom: none;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table .row.detail div {
  position: relative;
}

.table .row.detail div > span {
  font-size: 20px;
  position: absolute;
  left: -27px;
}

.table .row button {
  padding: 5px 4px;
}

.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.memberInfo-cell {
  min-height: 50px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.paging ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  width: 350px;
  margin-top: 40px;
}

.paging ul .previous {
  font-size: 20px;
}

.paging ul .next {
  font-size: 20px;
}

.paging ul .previous.disabled {
  display: none;
}

.paging ul .num.current {
  font-size: 20px;
  color: #6ea7db;
}

.paging ul .num {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #333;
}

.chat-paging ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.chat-paging ul .previous {
  font-size: 16px;
}

.chat-paging ul .next {
  font-size: 16px;
}

.chat-paging ul .previous.disabled {
  display: none;
}

.chat-paging ul .num.current {
  font-size: 16px;
  color: #6ea7db;
}

.chat-paging ul .num {
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: #333;
}

.rdw-image-modal-btn {
  color: #333;
}

.rdw-image-modal-upload-option-label {
  word-break: break-all;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 509;
}

.calendar {
  position: absolute;
  z-index: 510;
  right: 0px;
}

.calendar .rdrStaticRangeLabel {
  color: #333;
}

.chat-left {
  word-wrap: break-all;
  background-color: #f7f7f7;
  border-radius: 0 30px 30px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 15px;
}

.chat-right {
  border-radius: 30px 0 30px 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-right: 8px;
  max-width: 500px;
  padding: 15px;
  word-break: break-all;
}

.chat-box {
  align-items: flex-start;
  display: flex;
}